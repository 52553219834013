<template>
    <div class="footer-component">
        <div class="footer">
        <div class="grupo">
                <img src="/img/grupo.svg" alt="">
        </div>
        <div class="copy-footer">
            <div class="endereco">
                <span>geral@pavisani.com</span>
                <p>Avenida 21 de Janeiro, Morro Bento, Luanda-Angola comercial@pavisani.com <br> +244 923 456 789</p>
            </div>
            <div class="redes">
                <p>© 2024 - Todos Direitos Reservados</p>
                <div class="redes-icons">
                 <i class="fa  fa-facebook"></i>
                <i class="fa  fa-instagram"></i>
                <i class="fa fa-linkedin"></i>
                </div>
                <div class="power-by">
                        <p>Powered by: <a href="https://vibeagency.ao/" target="_blank">VIBE Agency</a> </p>
                </div>
            </div>
          
        </div>
    </div>
    </div>
</template>

<script>
export default{
    name:'footerComponent',

}
</script>

<style scoped>
.footer-component{
    width: 100%;
    background-color: #EFEFEF;

}
.footer{
    width: 90%;
    border-top: 2px solid #ACACAC;
    /*margin-top: 8%;*/
    display: flex;
    margin-left: auto;
    margin-right: auto;
}
.grupo{
    background-color: #464545;
    width: 15%;
    padding: 10px;
}
.grupo img{
    width: 100%;
    padding-top: 30px;
}

.copy-footer{
    display: flex;
   width: 80%;
   flex-wrap: wrap;
  
}
.endereco{
    width: 50%;
    text-align: start;
    padding: 30px 50px 50px;
}
.endereco span{
    font-weight: bold;
    font-size: 25px;
}
.endereco p{
    font-size: 14px;
    color: #ACACAC;
}
.redes{
    width: 50%;
    text-align: end;
    padding-top: 30px;

}
.redes-icons{
    display: flex;
    width: 100%;
    justify-content: end;
}

.redes-icons i{
    color: white;
   padding: 8px;
   margin: 1px;
   width: 30px;
   height: 30px;
   border-radius: 50px;
    background-color: black;
    text-align: center;
    cursor: pointer;

}
.power-by{
    margin-top: 4%;
    font-size: 14px;
}
.power-by a{
    text-decoration: none;
    color: #DC6400;
    font-weight: bold;
}
@media screen and (max-width: 768px) {
    .endereco{
        width: 100%;
        font-size: 30px;
        padding: 0;
        padding-top: 10px;
        padding-left: 10px;
    }
    .endereco span{
        font-size: 15px;
    }
    .endereco p{
        font-size: 12px;

    }
.copy-footer{
    flex-wrap: wrap;
    display: block;
}
.redes{
    width: 100%;
    text-align: start;
    padding: 0;
    padding-top: 0;
}
.redes p{
    font-size: 14px;
    padding: 10px;
}
.redes-icons{
    justify-content: start;
    padding-left: 10px;
    
}
.grupo{
    width: 50%;
    padding-top: 0;
}
.redes-icons i{
    width: 25px;
    height: 25px;
    padding: 5px;
}


}

</style>