import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/parceiros',
    name: 'Parceiros',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "parceiros" */ '../views/ParceirosView.vue')
  },
   {
    path: '/servico',
    name: 'Servico',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "parceiros" */ '../views/ServicoView.vue')
  },
/*  {
    path: '/Blog',
    name: 'Blog',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "parceiros"  '../views/BlogView.vue')
  },*/
  {
    path: '/galeria',
    name: 'Galeria',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "parceiros" */ '../views/galeriaView.vue')
  },
  {
    path: '/Contacto',
    name: 'Contacto',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "parceiros" */ '../views/contactoView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
  ,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition; // Se houver uma posição salva, use-a (exemplo: ao clicar no botão Voltar)
    } else {
      return { top: 0 }; // Caso contrário, retorne a posição inicial no topo
    }
  }
})

export default router
