import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.min.css' // Importe o arquivo CSS do Bootstrap
import { pt_BR } from '../src/locales/pt_BR.js'
import { en } from '../src/locales/en.js'
import '../public/css/global.css'; 

const languages = {
    pt_BR,
    en
}

const i18n = createI18n({
    locale: 'pt_BR',
    fallbackLocale: 'pt_BR',
    messages: languages
})

const app = createApp(App)
app.use(router)
app.use(i18n) // Adicione o plugin i18n criado ao aplicativo Vue
app.mount('#app')
